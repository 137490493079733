/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import qs from "qs";
/**
 * 40000 未知
 * 40001 错误的token信息，此token对应的用户不应该执行此操作
 * 42200 表单验证出错
 * 40400 页面/数据 不存在
 * 40300 拒绝访问
 * 40100 未认证
 */
const err400 = (code) => {
  if (code !== 40000) return "next";
  window.alert("code:400错误，参数错误！");
};
const err500 = (code) => {
  if (code !== 500) return "next";
  window.alert("code:500错误，请联系管理员！");
};
const errorHandlerChain = [err400, err500];
const errorHandle = (error, wd) => {
  for (const handler of errorHandlerChain) {
    const result = handler(error, wd);
    if (result !== "next") break;
  }
};
// const isWX = isWechat();
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = "/";

axios.defaults.timeout = 300000; // 5分钟
axios.interceptors.request.use((config) => {
  if (
    localStorage.getItem("token") &&
    config.headers.requestType !== "noToken"
  ) {
    config.headers["zs-token"] = localStorage.getItem("token");
  }
  return config;
});
axios.interceptors.response.use(
  (res) => {
    // 填写工单时,判断是否能提交工单
    errorHandle(res.data.code);
    return res;
  },
  (err) => {
    errorHandle(err.response.data.code);
  }
);

const urlConvert = (url) => {
  return url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0
    ? url.replace(`${process.env.VUE_APP_EXTERNAL_LINK}`, "")
    : url;
};

export const get = (url, params = {}, token = true) => {
  if (url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0) {
    axios.defaults.baseURL = process.env.VUE_APP_WECHAT_URL;
  } else {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  }
  if (token)
    return axios.get(urlConvert(url), { params }).then((res) => res.data);
  return axios
    .get(urlConvert(url), { params, headers: { requestType: "noToken" } })
    .then((res) => res.data);
};
export const post = (url, params = {}, token = true) => {
  if (url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0) {
    axios.defaults.baseURL = process.env.VUE_APP_WECHAT_URL;
  } else {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  }
  if (token)
    return axios
      .post(urlConvert(url), qs.stringify(params))
      .then((res) => res.data);
  return axios
    .post(urlConvert(url), qs.stringify(params), {
      headers: { requestType: "noToken" },
    })
    .then((res) => res.data);
};
export const patch = (url, params = {}, token = true) => {
  if (url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0) {
    axios.defaults.baseURL = process.env.VUE_APP_WECHAT_URL;
  } else {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  }
  if (token)
    return axios
      .patch(urlConvert(url), qs.stringify(params))
      .then((res) => res.data);
  return axios
    .patch(urlConvert(url), qs.stringify(params), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data);
};
export const put = (url, params = {}, token = true) => {
  if (url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0) {
    axios.defaults.baseURL = process.env.VUE_APP_WECHAT_URL;
  } else {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  }
  if (token)
    return axios
      .put(urlConvert(url), qs.stringify(params))
      .then((res) => res.data);
  return axios
    .put(urlConvert(url), qs.stringify(params), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data);
};
export const deletes = (url, params = {}, token = true) => {
  if (url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0) {
    axios.defaults.baseURL = process.env.VUE_APP_WECHAT_URL;
  } else {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  }
  if (token)
    return axios.delete(urlConvert(url), { params }).then((res) => res.data);
  return axios
    .delete(urlConvert(url), {
      params,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((res) => res.data);
};

export const uploads = (url, params = {}, token = true) => {
  if (url.indexOf(process.env.VUE_APP_EXTERNAL_LINK) >= 0) {
    axios.defaults.baseURL = process.env.VUE_APP_WECHAT_URL;
  } else {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
  }
  if (token) {
    return axios.post(urlConvert(url), params, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=",
      },
    });
  }
  return axios.post(urlConvert(url), { params });
};
