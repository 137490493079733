import { createApp } from "vue";
import * as http from "@/assets/js/http";
import * as storage from "@/assets/js/storage";
import App from "./App.vue";
import router from "./router";
import componentUtils from "@/assets/js/componentUtils";
import {
  Area,
  Popup,
  Toast,
  Uploader,
  Popover,
  Grid,
  GridItem,
  Icon,
  ImagePreview,
  Dialog,
  Lazyload,
  ShareSheet,
} from "vant";
import VCalendar from "v-calendar";
import "vant/lib/index.css";
import "v-calendar/dist/style.css";
const app = createApp(App);
app
  .use(router)
  .use(Area)
  .use(Popup)
  .use(Uploader)
  .use(Popover)
  .use(Grid)
  .use(GridItem)
  .use(Icon)
  .use(Dialog)
  .use(ImagePreview)
  .use(componentUtils)
  .use(Toast)
  .use(VCalendar)
  .use(Lazyload)
  .use(ShareSheet);
app.config.globalProperties.$http = http;
app.config.globalProperties.$storage = storage;
app.mount("#app");
