
import { Options, Vue } from "vue-class-component";

@Options({
  created() {
    if (this.$route.fullPath === "/") {
      this.$router.push({ name: "index" });
    }
  },
})
export default class Root extends Vue {}
