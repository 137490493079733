import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  // RouteLocationNormalized,
} from "vue-router";
import Root from "../views/Root.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    component: Root,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "/index/castle",
        name: "castle",
        component: () => import("../views/castle/Index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach(
//   (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
//     console.log("to", to);
//     console.log("from", from);
//   }
// );

export default router;
