<template>
  <img v-if="noLazy" class="image" @click.stop.prevent="show" :src="src" />
  <img v-else class="image" @click.stop.prevent="show" v-lazy="src" />
  <van-image-preview
    v-model:show="showPopover"
    :images="[src]"
    :showIndex="false"
    closeable
    teleport="#app"
  >
    <template v-slot:cover>
      <div class="image-save-wrapper-bg">
        <div class="image-save-wrapper" @click.stop.prevent="imageSave">
          <span>保存图片</span>
        </div>
      </div>
    </template>
  </van-image-preview>
</template>

<script lang="js">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    src: {
      type: String,
      default: "",
    },
    noLazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  methods: {
    show() {
      this.showPopover = true;
    },
    getUrlBase64(url) {
      return new Promise(resolve => {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let img = new Image()
        img.crossOrigin = '*' //允许跨域
        img.src = url+`?t=${new Date().getTime()}`
        img.onload = function(e) {
          let imgTarget = '';
          try{
            imgTarget = e.path[0]
          }catch(error){
            console.log('不存在path')
          }
          if(!imgTarget){
            try{
              imgTarget = e.target
            }catch(error){
              console.log('不存在path')
            }
          }
          canvas.height = imgTarget.height
          canvas.width = imgTarget.width
          ctx.drawImage(img, 0, 0, imgTarget.width, imgTarget.height)
          let dataURL = canvas.toDataURL('image/png')
          canvas = null
          resolve(dataURL)
        }
      })
    },
    imageSave() {
      const _this = this
      const fileName = `${Date.parse(new Date())}`
      if (!this.$storage.isZsApp()) {
        this.getUrlBase64(this.src).then(base64 => {
          _this.$storage.zsImageShare(fileName, base64, '', process.env.VUE_APP_DEPLOY_URL)
        })
      }else{
        const form = new FormData();
        this.getUrlBase64(this.src).then(base64 => {
          form.append("file", _this.$storage.base64ToFile(base64, fileName) );
          _this.$http.uploads("/common/upload", form).then((res)=>{
            if(res.data.code === 200 && res.data.data.url){
              _this.$storage.zsImageShare(fileName, base64, res.data.data.url, process.env.VUE_APP_DEPLOY_URL)
            }
          })
        })
      }
    },
  },
})
export default class Back extends Vue {}
</script>

<style scoped lang="scss">
.image {
  width: 100%;
}
.image-save-wrapper-bg {
  width: 100%;
  height: 100%;
}
.image-save-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: px2rem(606px);
  height: px2rem(88px);
  background: linear-gradient(136deg, #1ee1a4 0%, #58e0c4 100%);
  box-shadow: 0 px2rem(14px) px2rem(20px) px2rem(1px) rgba(149, 225, 175, 0.35);
  border-radius: px2rem(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: px2rem(32px);
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
