
import { Options, Vue } from "vue-class-component";

@Options({
  created() {
    this.$toast.loading({
      message: "正在加载中...",
      duration: 0,
      overlay: true,
      loadingType: "spinner",
      className: "transform-mask-loader",
      zIndex: "99999999",
      teleport: "#app",
    });
  },
})
export default class Root extends Vue {}
