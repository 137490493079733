/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const setCookie = (c_name, value, expiredays) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
};
export const getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
};
export const clearCookie = (c_name) => {
  setCookie(c_name, "", -1);
};
export const ZS_IS_APP = "iOSShoubaApp";
export const ZS_IS_APP_ANDROID = "Android-ShouBa";
export const ZS_TOKEN = "token";
export const ZS_USER_ID = "userId";
export const ZS_AUTHORIZATION_KEY = "authorizationKey";
export const ZS_APP_VERSION = "appVersion";
export const ZS_COACH_ID = "coach_id";
export const ZS_USER_TYPE = "userType";
export const ACCOUNT_ID = "ZS_ACCOUNT_ID";
export const ACCOUNT_NAME = "ZS_ACCOUNT_NAME";
export const ACCOUNT_AVATAR = "ZS_AVATAR";
export const ACCOUNT_LOTTERY_NUM = "ZS_LOTTERY_NUM";
export const ACCOUNT_IS_SERVICE_STAR = "ZS_IS_SERVICE_STAR";
export const ACCOUNT_SERVICE_NAME = "ZS_SERVICE_NAME";
export const ACCOUNT_SERVICE_STATUS = "ZS_SERVICE_STATUS";
export const ACCOUNT_SERVICE_RANK = "ZS_SERVICE_RANK";
export const ACCOUNT_SERVICE_AVATAR = "ZS_SERVICE_AVATAR";
export const ACCOUNT_SERVICE_SLOGAN = "ZS_SERVICE_SLOGAN";
export const ACCOUNT_SERVICE_LOCATION = "ZS_SERVICE_LOCATION";
export const ACCOUNT_SERVICE_DETAIL_IMG = "ZS_SERVICE_DETAIL_IMG";
export const ACCOUNT_SERVICE_DETAIL_DESC = "ZS_SERVICE_DETAIL_DESC";
export const ACCOUNT_SERVICE_DETAIL_VIDEO = "ZS_SERVICE_DETAIL_VIDEO";
export const ACCOUNT_SERVICE_SORT = "ZS_SERVICE_SORT";
export const ZS_DOWNLOAD_URL = "https://shencesj.shouba.cn/r/Hs";
export const ZS_ACTIVITY_URL =
  "https://mp.weixin.qq.com/s/Bjh4x6WrA_Zj4IBgp5qTbQ";
export const ZS_LIVE_URL = "https://ssd.h5.xeknow.com/sl/2WWHf";
export const ZS_REGISTER_URL = "https://shencesj.shouba.cn/r/Hs";
export const ZS_SHARE_ACCESS_TOKEN = "ZS_SHARE_ACCESS_TOKEN";
export const ZS_SHARE_USER_ID = "ZS_SHARE_USER_ID";
export const ZS_SHARE_ACCESS_TOKEN_KEY = "accessToken";
export const ZS_SHARE_USER_ID_KEY = "userId";
export const isZsApp = () => {
  return (
    navigator.userAgent.indexOf(ZS_IS_APP) > -1 ||
    navigator.userAgent.indexOf(ZS_IS_APP_ANDROID) > -1
  );
};
export const goRegister = () => {
  let url = process.env.VUE_APP_SHARE_URL;
  const accessToken = localStorage.getItem(ZS_SHARE_ACCESS_TOKEN) || "";
  url += `?${ZS_SHARE_ACCESS_TOKEN_KEY}=${accessToken}`;
  const userId = localStorage.getItem(ZS_SHARE_USER_ID) || "";
  url += `&${ZS_SHARE_USER_ID_KEY}=${userId}`;
  window.open(url);
};
export const judgeMobile = () => {
  const u = navigator.userAgent;
  return {
    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    ios: !!u.match(/iPhone|iPod|iPad/i), //ios终端
    android: !!u.match(/Android/i), //android终端,
    wechat: !!(u.toLowerCase().match(/MicroMessenger/i) == "micromessenger"), //微信终端
  };
};
export const getUrlBase64 = (url = "") => {
  return new Promise((resolve) => {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let img = new Image();
    img.crossOrigin = "*"; //允许跨域
    img.src = url + `?t=${new Date().getTime()}`;
    img.onload = function (e) {
      let imgTarget = "";
      try {
        imgTarget = e.path[0];
      } catch (error) {
        console.log("不存在path");
      }
      if (!imgTarget) {
        try {
          imgTarget = e.target;
        } catch (error) {
          console.log("不存在path");
        }
      }
      canvas.height = imgTarget.height;
      canvas.width = imgTarget.width;
      ctx.drawImage(img, 0, 0, imgTarget.width, imgTarget.height);
      let dataURL = canvas.toDataURL("image/png");
      canvas = null;
      resolve(dataURL);
    };
  });
};
export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
export const base64ToFile = (urlData, fileName) => {
  if (typeof urlData != "string") {
    this.$toast("urlData不是字符串");
    return;
  }
  var arr = urlData.split(",");
  var type = arr[0].match(/:(.*?);/)[1];
  var fileExt = type.split("/")[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName + "." + fileExt, {
    type: type,
  });
};
// 保存/分享图片
export const zsImageShare = (
  shareName = "",
  shareImgBase64 = "",
  shareImgUrl = "",
  shareUrl = ""
) => {
  if (isZsApp()) {
    const params = JSON.stringify({
      // image: shareImgUrl, //图片链接
      // isHideBoard: 1,
      // platforms: "timeline",
      title: shareName, //分享标题
      descr: shareName, //分享文案
      url: shareUrl, //分享出去链接
    });
    if (judgeMobile().android) {
      try {
        // android.html_share(params);
        android.savedPhoto(shareImgUrl);
      } catch (error) {
        console.log("不存在android对象", error);
      }
    } else if (judgeMobile().ios) {
      try {
        window.webkit.messageHandlers.savedPhoto.postMessage(shareImgUrl);
      } catch (error) {
        console.log("不存在window.webkit对象", error);
      }
    }
  } else {
    const fileName = `${shareName}.png`;
    var blob = dataURLtoBlob(shareImgBase64);
    const elink = document.createElement("a");
    // 设置下载文件名
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
  }
};
export const zsAppPageBack = () => {
  if (isZsApp()) {
    if (judgeMobile().android) {
      try {
        android.comeBack();
      } catch (error) {
        console.log("不存在android对象", error);
      }
    } else if (judgeMobile().ios) {
      try {
        window.webkit.messageHandlers.comeBack.postMessage(0);
      } catch (error) {
        console.log("不存在window.webkit对象", error);
      }
    }
  } else {
    window.history.go(-(window.history.length - 1));
  }
};
export const isWxMini = (_this, callback) => {
  return new Promise((resolve) => {
    if (!judgeMobile().wechat) {
      console.log("不在微信或者小程序中");
      resolve(false);
    } else {
      parent.wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }
  }).then((e) => {
    if (e) {
      _this.$toast({
        message:
          "欢迎小可爱进入瘦吧元宇宙探索，您现在体验的是观赏版本，如需体验完整版，请下载瘦吧APP。",
        icon: "none",
      });
    } else {
      callback && callback();
    }
  });
};
export const zsWxHtmlShare = (_this) => {
  let url = `${process.env.VUE_APP_DEPLOY_URL}/index?exhibitionhall=1`;
  // const accessToken = localStorage.getItem(ZS_SHARE_ACCESS_TOKEN) || "";
  // url += `&${ZS_SHARE_ACCESS_TOKEN_KEY}=${accessToken}`;
  // const userId = localStorage.getItem(ZS_SHARE_USER_ID) || "";
  // url += `&${ZS_SHARE_USER_ID_KEY}=${userId}`;
  const params = JSON.stringify({
    url: url,
    title: process.env.VUE_APP_SHARE_DEFAULT_TITLE,
    descr: process.env.VUE_APP_SHARE_DEFAULT_DESC,
    thumImage: `${process.env.VUE_APP_DEPLOY_URL}${process.env.VUE_APP_SHARE_IMG}`, //分享图片
    // platforms: ["wechat"],
    isHideBoard: 0,
  });
  if (isZsApp()) {
    if (judgeMobile().android) {
      try {
        android["html_share"](params);
      } catch (error) {
        console.log("不存在android对象", error);
      }
    } else if (judgeMobile().ios) {
      try {
        window.webkit.messageHandlers["html_share"].postMessage(params);
      } catch (error) {
        console.log("不存在window.webkit对象", error);
      }
    }
  } else {
    goZsAppPage(_this, 10);
  }
};
export const zsInnerHtmlShare = (_this) => {
  const params = JSON.stringify({
    url: `${process.env.VUE_APP_DEPLOY_URL}/index?exhibitionhall=1`,
    title: process.env.VUE_APP_SHARE_DEFAULT_TITLE,
    descr: process.env.VUE_APP_SHARE_DEFAULT_DESC,
    thumImage: `${process.env.VUE_APP_DEPLOY_URL}${process.env.VUE_APP_SHARE_IMG}`, //分享图片
    contentId: "",
    commission: 0,
  });
  if (isZsApp()) {
    if (judgeMobile().android) {
      try {
        android["html_share"](params);
      } catch (error) {
        console.log("不存在android对象", error);
      }
    } else if (judgeMobile().ios) {
      try {
        window.webkit.messageHandlers["html_share"].postMessage(params);
      } catch (error) {
        console.log("不存在window.webkit对象", error);
      }
    }
  } else {
    goZsAppPage(_this, 10);
  }
};
export const goZsAppPage = (
  _this,
  jumpValue = "",
  coachId = "",
  externalLink = ""
) => {
  const paramsBase = {
    jumpValue,
  };
  if (!coachId) {
    if (getAccountInfo() && getAccountInfo()[ACCOUNT_ID]) {
      paramsBase.coachId = getAccountInfo()[ZS_COACH_ID];
    }
  }
  if (isZsApp()) {
    const params = JSON.stringify(paramsBase);
    if (judgeMobile().android) {
      try {
        android.onshow_link(params);
      } catch (error) {
        console.log("不存在android对象", error);
      }
    } else if (judgeMobile().ios) {
      try {
        window.webkit.messageHandlers.onshow_link.postMessage(params);
      } catch (error) {
        console.log("不存在window.webkit对象", error);
      }
    }
  } else {
    if (externalLink) {
      _this.outsideIframeShow(externalLink);
      // window.open(externalLink);
    } else {
      goRegister();
      // _this.outsideIframeShow(ZS_DOWNLOAD_URL);
      // window.open(ZS_DOWNLOAD_URL);
    }
  }
};
export const setToken = (token) => {
  if (token) {
    localStorage.setItem(ZS_TOKEN, token);
  }
};
export const debugToast = (_this, message) => {
  return (
    process.env.NODE_ENV == "development" &&
    _this.$toast({
      message: message,
    })
  );
};
export const getToken = () => {
  return localStorage.getItem(ZS_TOKEN);
};
export const setAccountInfo = (data) => {
  localStorage.setItem(ACCOUNT_ID, data.account_id);
  localStorage.setItem(ACCOUNT_NAME, data.account_name);
  localStorage.setItem(ACCOUNT_AVATAR, data.avatar);
  localStorage.setItem(ZS_COACH_ID, data.coach_id);
  if (data.serviceStar && data.serviceStar != null && data.serviceStar.id) {
    localStorage.setItem(ACCOUNT_IS_SERVICE_STAR, "1");
    localStorage.setItem(ACCOUNT_SERVICE_NAME, data.serviceStar.name);
    localStorage.setItem(ACCOUNT_SERVICE_AVATAR, data.serviceStar.avatar);
    localStorage.setItem(ACCOUNT_SERVICE_SLOGAN, data.serviceStar.slogan);
    localStorage.setItem(ACCOUNT_SERVICE_LOCATION, data.serviceStar.location);
    localStorage.setItem(
      ACCOUNT_SERVICE_DETAIL_IMG,
      data.serviceStar.detail_img
    );
    localStorage.setItem(
      ACCOUNT_SERVICE_DETAIL_DESC,
      data.serviceStar.detail_desc
    );
    localStorage.setItem(
      ACCOUNT_SERVICE_DETAIL_VIDEO,
      data.serviceStar.detail_video
    );
    localStorage.setItem(ACCOUNT_SERVICE_SORT, data.serviceStar.sort);
    localStorage.setItem(ACCOUNT_SERVICE_STATUS, data.serviceStar.status);
    localStorage.setItem(ACCOUNT_SERVICE_RANK, data.serviceStar.rank);
  }
};
export const getAccountInfo = () => {
  const data = {};
  data[ACCOUNT_ID] = localStorage.getItem(ACCOUNT_ID);
  data[ACCOUNT_NAME] = localStorage.getItem(ACCOUNT_NAME);
  data[ACCOUNT_AVATAR] = localStorage.getItem(ACCOUNT_AVATAR);
  data[ACCOUNT_IS_SERVICE_STAR] = localStorage.getItem(ACCOUNT_IS_SERVICE_STAR);
  data[ZS_COACH_ID] = localStorage.getItem(ZS_COACH_ID);
  if (localStorage.getItem(ACCOUNT_IS_SERVICE_STAR)) {
    data[ACCOUNT_SERVICE_NAME] = localStorage.getItem(ACCOUNT_SERVICE_NAME);
    data[ACCOUNT_SERVICE_AVATAR] = localStorage.getItem(ACCOUNT_SERVICE_AVATAR);
    data[ACCOUNT_SERVICE_SLOGAN] = localStorage.getItem(ACCOUNT_SERVICE_SLOGAN);
    data[ACCOUNT_SERVICE_LOCATION] = localStorage.getItem(
      ACCOUNT_SERVICE_LOCATION
    );
    data[ACCOUNT_SERVICE_DETAIL_IMG] = localStorage.getItem(
      ACCOUNT_SERVICE_DETAIL_IMG
    );
    data[ACCOUNT_SERVICE_DETAIL_DESC] = localStorage.getItem(
      ACCOUNT_SERVICE_DETAIL_DESC
    );
    data[ACCOUNT_SERVICE_DETAIL_VIDEO] = localStorage.getItem(
      ACCOUNT_SERVICE_DETAIL_VIDEO
    );
    data[ACCOUNT_SERVICE_SORT] = localStorage.getItem(ACCOUNT_SERVICE_SORT);
    data[ACCOUNT_SERVICE_STATUS] = localStorage.getItem(ACCOUNT_SERVICE_STATUS);
    data[ACCOUNT_SERVICE_RANK] = localStorage.getItem(ACCOUNT_SERVICE_RANK);
  }
  return data;
};
export const checkAccountInfo = (_this) => {
  return new Promise((resolve, reject) => {
    const token = getToken(); // || GetQueryString(ZS_TOKEN);
    // console.log(token, "token");
    const accountInfo = getAccountInfo();
    // console.log(accountInfo, "accountInfo");
    if (token && accountInfo && accountInfo[ACCOUNT_ID]) {
      resolve(accountInfo);
    } else {
      reject("您尚未登录，正在前往登录...");
    }
  }).catch((message) => {
    if (isZsApp()) {
      _this.$toast({
        message: message,
      });
    }
    // 跳转原生注册
    goZsAppPage(_this, 10);
  });
};
export const fullScreen = () => {
  if (document.documentElement.RequestFullScreen) {
    document.documentElement.RequestFullScreen();
  }
  //兼容火狐
  if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
  //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
  if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  }
  //兼容IE,只能写msRequestFullscreen
  if (document.documentElement.msRequestFullscreen) {
    document.documentElement.msRequestFullscreen();
  }
};
export const exitFullScreen = () => {
  if (document.exitFullScreen) {
    document.exitFullscreen();
  }
  //兼容火狐
  if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  }
  //兼容谷歌等
  if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
  //兼容IE
  if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};
export const GetQueryString = (name) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};
export const setWechatConfig = ({ appId, timestamp, nonceStr, signature }) => {
  console.log("setWechatConfig ing...");
  wx.config({
    debug: false,
    appId,
    timestamp,
    nonceStr,
    signature,
    jsApiList: [
      "updateAppMessageShareData",
      "updateTimelineShareData",
      "onMenuShareTimeline",
      "onMenuShareAppMessage",
    ],
    fail(err) {
      console.log("setShareConfig err", err);
    },
  });
};
export const setWechatShare = ({ title, link, desc, imgUrl }) => {
  console.log("setWechatShare ing...");
  const shareParam = {
    title: title || process.env.VUE_APP_SHARE_DEFAULT_TITLE, // 分享标题
    desc: desc || process.env.VUE_APP_SHARE_DEFAULT_DESC, // 分享描述
    link: link
      ? process.env.VUE_APP_DEPLOY_URL + link
      : process.env.VUE_APP_DEPLOY_URL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl:
      imgUrl ||
      `${process.env.VUE_APP_DEPLOY_URL}${process.env.VUE_APP_SHARE_IMG}`, // 分享图标
  };
  wx.ready(() => {
    // 需在用户可能点击分享按钮前就先调用
    wx.updateTimelineShareData({
      ...shareParam,
      success() {
        console.log("updateTimelineShareData success");
      },
      fail(err) {
        console.log(err, "updateTimelineShareData");
      },
    });
    wx.updateAppMessageShareData({
      ...shareParam,
      success() {
        console.log("updateAppMessageShareData success");
      },
      fail(err) {
        console.log(err, "updateAppMessageShareData");
      },
    });
  });
  wx.onMenuShareTimeline({
    ...shareParam,
    success() {
      console.log("onMenuShareTimeline success");
    },
    fail(err) {
      console.log(err, "onMenuShareTimeline");
    },
  });
  wx.onMenuShareAppMessage({
    ...shareParam,
    success() {
      console.log("onMenuShareAppMessage success");
    },
    fail(err) {
      console.log(err, "onMenuShareAppMessage");
    },
  });
};
